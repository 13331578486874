import React from 'react';
import {
  withPreviewResolver,
  WithPreviewResolverProps,
} from 'gatsby-source-prismic';
import { linkResolver } from 'utils/linkResolver';
import { PageProps } from 'gatsby';

const PreviewPage: React.FC<WithPreviewResolverProps & PageProps> = ({
  isPreview,
}) => {
  const previewText = isPreview ? 'Loading' : 'Not a preview!';

  return <div>{previewText}</div>;
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME || '',
  linkResolver: () => linkResolver,
});
